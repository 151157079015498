<template>
  <v-card class="mt-4 mx-auto" max-width="400">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }" >
          <v-card
            v-bind="attrs"
            v-on="on"
            class="mx-auto"
            :class="{ 'on-hover': hover }"
            :color="color"
            :elevation="hover ? 16 : 2"
            max-width="calc(100% - 32px)"
            @click="$store.commit('redireccion', redireccion)"
          >
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
            ></v-sparkline>
          </v-card>
        </v-hover>
      </template>
      <span>Ver</span>
    </v-tooltip>

    <v-card-text class="pt-0">
      <div class="title font-weight-light mb-2">
        {{ title }}
      </div>
      <div class="subheading font-weight-light grey--text">
        Last Campaign Performance
      </div>
      <v-divider class="my-2"></v-divider>
      <v-icon class="mr-2" small> mdi-clock </v-icon>
      <span class="caption grey--text font-weight-light"
        >last registration 26 minutes ago</span
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["title", "color", "redireccion"],
  data: () => ({
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240],
  }),
};
</script>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>