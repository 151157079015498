<template>
    <v-app>
        <v-row class="ma-5">
            <v-col cols="12" md="7" >
                <v-row>
                    <v-col cols="12" sm="6" >
                        <CardActions :redireccion="'/admin/crud_sectores'" :descripcion="'Creacion, edicion y eliminacion de los distintas areas de su empresa'" :icon="'mdi-graph-outline'" :nombre="'Areas'" :texto="'Areas'" :imagen="require('../../assets/sectores.png')" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <CardActions :redireccion="'/admin/crud_documentos'" :descripcion="'Creacion, edicion y eliminacion de los distintos tipos documentales de su empresa'" :icon="'mdi-file-document-multiple-outline'" :nombre="'Documentos'" :texto="'Documentos'" :imagen="require('../../assets/documentos.png')" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <CardActions :redireccion="'/admin/crud_workflows'" :descripcion="'Creacion, edicion y eliminacion de los distintos tipos flujos de trabajo de su empresa'" :icon="'mdi-chart-timeline-variant'" :nombre="'WorkFlows'" :texto="'Flujos de trabajo'" :imagen="require('../../assets/workflows.png')" />
                    </v-col>
                    <!---
                    <v-col cols="12" sm="6">
                        <CardActions :redireccion="'/admin/crud_archivador'" :descripcion="'Creacion, edicion y eliminacion de los encargados de archivar los documentos de su empresa'" :icon="'mdi-library'" :nombre="'Archivador'" :texto="'Archivador'" :imagen="require('../../assets/archivador.png')" />
                    </v-col>
                     -->
                    <v-col cols="12" sm="6">
                        <CardActions :redireccion="'/admin/crud_validaciones'" 
                        :descripcion="'Creacion, edicion y eliminacion de las busquedas de informacion en su empresa '" :icon="'mdi-magnify'" :nombre="'Busquedas'" 
                        :texto="'Busquedas'" 
                        :imagen="require('../../assets/buscar.png')" />
                    </v-col>
                   
                </v-row>
            </v-col>
            <v-col cols="12" sm="5">
                <v-row>
                    <v-col cols="6" sm="12">
                         <CardGraficas
                        :color="'cyan'"
                        class="mb-10"
                        :title="'Uso de la plataforma'"
                        :redireccion="'/admin/crud_graficas/Uso de la plataforma'"
                        />
                    </v-col>
                    <v-col cols="6" sm="12">
                          <CardGraficas :redireccion="'/admin/crud_graficas/Reportes'" :color="'#311B92'" :title="'Reportes por areas '" />
                    </v-col>
                </v-row> 
            </v-col>
        </v-row>
    </v-app>
</template>
<script>
import CardActions from '../../components/admin/CardActions.vue'
import CardGraficas from '../../components/admin/CardGraficas.vue'

export default {
    components: {
        CardActions,
        CardGraficas
    },
    data(){
        return {
        }
    }
}
</script>