<template>
  <v-container fluid fill-heigth class="ma-0 pa-0">
    <FolderDocuments :items="items"></FolderDocuments>
  </v-container>
</template>

<script>
import FolderDocuments from "../../components/qr/FolderDocuments.vue";
export default {
  components: { FolderDocuments },
  data() {
    return {
      key: "value",
    };
  },
  created() {
    console.log(this.items);
  },
  methods: {},
  computed: {
    items() {
      return this.$route.params.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
