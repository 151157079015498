<template>
  <div>
    <v-container
      fluid
      class="d-flex flex-wrap justify-md-space-between justify-center"
    >
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        :class="{
          'mb-2': $vuetify.breakpoint.smAndDown,
          'text-capitalize': true,
        }"
        color="primary"
        @click="$router.push({ name: 'qr' })"
        >Volver a la tabla</v-btn
      >
      <v-pagination
        :length="archivedData.length"
        :total-visible="7"
        v-model="page"
        elevation="0"
      ></v-pagination>
    </v-container>

    <v-container fluid class="pa-0 ma-0 mx-auto" max-width="747px">
      <!-- Barra de herramientas -->
      <!-- <div class="d-flex flex-wrap justify-start mb-2" ref="refToolBar">
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          :class="{
            'mb-2': $vuetify.breakpoint.smAndDown,
            'text-capitalize': true,
          }"
          color="primary"
          @click="showDocumentFullScreen"
        >
          Ver documento completo
        </v-btn>
      </div> -->

      <!-- Imagen y detalles de la imagen -->
      <div class="d-flex" ref="refDetailsAndDoc">
        <div class="shrink" v-if="$vuetify.breakpoint.mdAndUp">
          <v-list shaped min-width="250px">
            <v-subheader>Detalles del documento</v-subheader>

            <v-list-item
              v-for="(data, index) in [
                {
                  title: 'Sector',
                  data: archivedData[page - 1].document.nombre_sector,
                  icon: 'mdi-google-circles-extended',
                },

                {
                  title: 'Fecha',
                  data: formatedDate(
                    archivedData[page - 1].document.fecha_creacion_int
                  ),
                  icon: 'mdi-calendar-import',
                },
                {
                  title: 'Autor',
                  data: archivedData[page - 1].document.autor,
                  icon: 'mdi-account',
                },
                {
                  title: 'Tipo de documento',
                  data: fyleType(
                    this.archivedData[this.page - 1].document.tipo
                  ),
                  icon: 'mdi-file-hidden',
                },
              ]"
              :key="index"
            >
              <v-list-item-icon>
                <v-icon color="primary" v-text="data.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="data.title"></v-list-item-title>
                <v-list-item-subtitle
                  class="text-capitalize text-wrap text-body-1 mt-2 ml-2"
                  v-text="data.data"
                >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="shrink mx-auto" style="width: 850px; min-width: 320px;">
          <v-window vertical v-model="page">
            <v-window-item
              min-width="320px"
              width="100%"
              overflow
              :key="`window-${n}`"
              v-for="n in page"
              :value="n"
            >
              <div class="" v-if="isImage(archivedData[n - 1].document.tipo)">
                <v-img
                  class="mx-auto"
                  contain
                  min-width="320px"
                  max-width="850px"
                  :src="
                    `https://drive1.educandote.co/siett/${
                      archivedData[n - 1].document.token
                    }/${archivedData[n - 1].document.nombre}.${
                      archivedData[n - 1].document.tipo
                    }`
                  "
                />
              </div>
              <div v-else>
                <pdf
                  :src="
                    `https://drive1.educandote.co/siett/${
                      archivedData[n - 1].document.token
                    }/${archivedData[n - 1].document.nombre}.${
                      archivedData[n - 1].document.tipo
                    }`
                  "
                ></pdf>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// import DocumentTools from "./FolderDocuments/DocumentTools";
import moment from "moment";
import pdf from "vue-pdf";
// import Swal from "sweetalert2";
export default {
  props: ["items"],
  components: { pdf },
  data() {
    return {
      page: 1,
      archivo: {},
      archivedData: [],
      archivedData_: [],
      defaultSelected: null,
      selectedFolderList: [],
    };
  },
  watch: {
    archivo(val) {
      console.log(val);
    },
  },

  created() {
    this.archivedData = this.items;
    // console.log(this.$route);
    // console.log(this.items);

    // /*
    //   Note:
    //     -- Keep one of those op.
    // */

    // // Storage folders data
    // const { carpetas } = this.items;
    // const foldersDataArrayParsed = [];
    // carpetas.forEach((item) => {
    //   foldersDataArrayParsed.push(JSON.parse(item));
    // });

    // this.selectedFolderList = [...foldersDataArrayParsed];
    // let Obj = {
    //   archivos: [
    //     {
    //       autor: "Eduard Marrugo",
    //       estado: "habilitada",
    //       fecha_adjuntado: 1648563799000,
    //       meta_datos: [],
    //       nombre: 0,
    //       nombre_sector: "archivadores",
    //       sector: "qfVGMzYB3l",
    //       tipo: "blob",
    //       token: "1082859958_ydb58ngqh2f2td7cqes14a2ew",
    //     },
    //   ],
    //   estado: "habilitada",
    //   meta_datos: [],
    //   nombre: "Otros adjuntos",
    // };

    // console.log({ datosCarpeta: foldersDataArrayParsed });
    // console.log({ unaCarpeta: this.selectedFolderList[0] });

    // this.defaultSelected = this.selectedFolderList[0];

    // //Folder Data
    // this.archivedData_ = [];
    // const archivedData_ArrayParsed = [];
    // this.defaultSelected;
    // // Storage docs data
    // this.archivedData = [];
    // const { datos_archivos } = this.items;
    // const archivedDataArrayParsed = [];
    // datos_archivos.forEach((element) => {
    //   archivedDataArrayParsed.push(JSON.parse(element));
    // });
    // this.archivedData = [...archivedDataArrayParsed];

    // if (this.$vuetify.breakpoint.mdAndUp) {
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", Swal.stopTimer);
    //       toast.addEventListener("mouseleave", Swal.resumeTimer);
    //     },
    //   });

    //   Toast.fire({
    //     icon: "info",
    //     title:
    //       "<p>Si el archivo es una tipo imagen, puedes colocar el mouse encima para hacer zoom. </p>",
    //   });
    // }
  },
  computed: {},
  beforeCreate() {
    //Quitar esto de acac
    // if (this.$route.params?.data) {
    // } else {
    //   this.$router.push({ path: "/buscador" });
    // }
  },
  methods: {
    showDocumentFullScreen() {
      window.open(
        `https://drive1.educandote.co/siett/${
          this.archivedData[this.page - 1].document.token
        }/${this.archivedData[this.page - 1].document.nombre}.${
          this.archivedData[this.page - 1].document.tipo
        }`,
        "_blank"
      );
    },
    fyleType(doc) {
      return this.isImage(doc) ? "Imagen" : doc;
    },
    isImage(img) {
      let arrayTpe = ["bmp", "svg", "jpge", "jpeg", "blob", "png", "jpg"];
      return arrayTpe.includes(img);
    },
    handlerWindow(event) {
      console.log(event);
    },
    formatedDate(date) {
      return moment(date).format("YYYY-MM-DD hh:ss")
        ? moment(date).format("YYYY-MM-DD hh:ss")
        : date;
    },
  },
};
</script>

<style lang="scss" scoped></style>
