var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"d-flex flex-wrap justify-md-space-between justify-center",attrs:{"fluid":""}},[_c('v-btn',{class:{
        'mb-2': _vm.$vuetify.breakpoint.smAndDown,
        'text-capitalize': true,
      },attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'qr' })}}},[_vm._v("Volver a la tabla")]),_c('v-pagination',{attrs:{"length":_vm.archivedData.length,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-container',{staticClass:"pa-0 ma-0 mx-auto",attrs:{"fluid":"","max-width":"747px"}},[_c('div',{ref:"refDetailsAndDoc",staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"shrink"},[_c('v-list',{attrs:{"shaped":"","min-width":"250px"}},[_c('v-subheader',[_vm._v("Detalles del documento")]),_vm._l(([
              {
                title: 'Sector',
                data: _vm.archivedData[_vm.page - 1].document.nombre_sector,
                icon: 'mdi-google-circles-extended',
              },

              {
                title: 'Fecha',
                data: _vm.formatedDate(
                  _vm.archivedData[_vm.page - 1].document.fecha_creacion_int
                ),
                icon: 'mdi-calendar-import',
              },
              {
                title: 'Autor',
                data: _vm.archivedData[_vm.page - 1].document.autor,
                icon: 'mdi-account',
              },
              {
                title: 'Tipo de documento',
                data: _vm.fyleType(
                  this.archivedData[this.page - 1].document.tipo
                ),
                icon: 'mdi-file-hidden',
              } ]),function(data,index){return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(data.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.title)}}),_c('v-list-item-subtitle',{staticClass:"text-capitalize text-wrap text-body-1 mt-2 ml-2",domProps:{"textContent":_vm._s(data.data)}})],1)],1)})],2)],1):_vm._e(),_c('div',{staticClass:"shrink mx-auto",staticStyle:{"width":"850px","min-width":"320px"}},[_c('v-window',{attrs:{"vertical":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},_vm._l((_vm.page),function(n){return _c('v-window-item',{key:("window-" + n),attrs:{"min-width":"320px","width":"100%","overflow":"","value":n}},[(_vm.isImage(_vm.archivedData[n - 1].document.tipo))?_c('div',{},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","min-width":"320px","max-width":"850px","src":("https://drive1.educandote.co/siett/" + (_vm.archivedData[n - 1].document.token) + "/" + (_vm.archivedData[n - 1].document.nombre) + "." + (_vm.archivedData[n - 1].document.tipo))}})],1):_c('div',[_c('pdf',{attrs:{"src":("https://drive1.educandote.co/siett/" + (_vm.archivedData[n - 1].document.token) + "/" + (_vm.archivedData[n - 1].document.nombre) + "." + (_vm.archivedData[n - 1].document.tipo))}})],1)])}),1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }